import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { range } from 'lodash';
import React, { FC, RefObject } from 'react';
import { ApiMeta } from '../../../api';
import { useWindowScroll } from '../../lib/hooks';
import styles from './styles.module.scss';

interface PaginationProps {
    infinite?: boolean,
    isLoading?: boolean,
    meta: ApiMeta,
    onChange: (page: number) => void,
}

const Pagination: FC<PaginationProps> = ({ infinite, isLoading, meta, onChange }): JSX.Element => {
    const divRef: RefObject<HTMLDivElement> = React.createRef();

    const handleChange = (e: any, page: number): void => {
        e.preventDefault();
        onChange(page);
    }

    useWindowScroll((e: any) => {
        if (infinite &&
            meta.current_page < meta.last_page && 
            !isLoading &&
            divRef?.current &&
            divRef.current.getBoundingClientRect().bottom <= window.innerHeight + 200
        ) {
            onChange(meta.current_page + 1);
        }
    }, 100, [isLoading, infinite, divRef, meta]);

    if (infinite) {
        return <div ref={divRef} />;
    }

    return (
        <div className={styles.pagination}>
            {meta.current_page > 1 && (<a href="." onClick={(e) => handleChange(e, meta.current_page - 1)}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </a>)}
            
            {meta.current_page - 3 >= 2 && <a href="." className={styles.isDisabled}>...</a>}

            {range(1, meta.total + 1).map((index) => {
                const max = Math.ceil(meta.total / meta.per_page);
                let cmax = meta.current_page + 3;

                if (cmax > max) {
                    cmax = max;
                }

                return index >= (meta.current_page - 3) && index <= cmax ? (
                    <a
                        key={`pagi-${index}`}
                        href="."
                        className={meta.current_page === index ? styles.isActive : ''}
                        onClick={(e) => handleChange(e, index)}
                    >
                        {index}
                    </a>
                ) : null;
            })}

            {meta.last_page > 5 && meta.current_page <= (meta.last_page - 4) && <a href="." className={styles.isDisabled}>...</a>}

            {meta.current_page < meta.last_page && (<a href="." onClick={(e) => handleChange(e, meta.current_page + 1)}>
                <FontAwesomeIcon icon={faArrowRight} />
            </a>)}
        </div>
    )
}

export default Pagination;
