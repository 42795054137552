import React, { FC, HTMLAttributes, useState } from 'react';
import styles from './styles.module.scss';

interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {
    noResults?: string | boolean,
}

const TableRow: FC<TableRowProps> = ({ children, noResults }): JSX.Element => {
    const [ expand, setExpand ] = useState<boolean>(false);

    const handleExpand = (): void => {
        setExpand((o) => !o);
    }

    return (
        <tr className={expand ? styles.expanded : ''}>
            {noResults ? (
                <td colSpan={100}>
                    <em>{noResults === true ? 'No results' : noResults}</em>
                </td>
            ) : React.Children.map(children, (child) => {
                return React.isValidElement(child)
                    ? React.cloneElement(child, { onExpand: handleExpand })
                    : child;
            })}
        </tr>
    );
}

export default TableRow;
