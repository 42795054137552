import { faPlus, faPencil, faTrashAlt, faCopy } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast, useTableSort } from '../../../RbKit';
import api, { ApiMeta } from '../../../api';
import { ApiForm } from '../../../api/form';
import { copyStr } from '../../../lib/util';

const FormListView: FC = (): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ orderBy, order, setSorting ] = useTableSort('firstName');
    const [ forms, setForms ] = useState<ApiForm[]>([]);

    const fetch = useCallback((query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listForms({ query, page, orderBy, order }).then(({ data }) => {
            setMeta(data.meta);
            setForms(data.data);
            setIsLoading(false);
        });
    }, [orderBy, order]);
    useEffect(() => fetch(), [orderBy, order, fetch]);

    const deleteForm = (formId: string): void => {
        api.deleteForm(formId).then(() => {
            fetch();
            toast('Form deleted successfully');
        });
    }

    return (<>
        <PageHeader title="Forms" breadcrumb={{'/forms': 'Overview'}}>
            <Button
                href="/forms/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>
        <Segment isLoading={isLoading}>
            <Table.Actions
                onSearch={fetch}
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell
                            sorted={[orderBy, 'name']}
                            order={order}
                            onSort={setSorting}
                        >
                            Subject
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Short code
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {forms.length > 0 ? forms.map((form) => (
                        <Table.Row key={`row-${form.id}`}>
                            <Table.Cell primary name="Name">
                                <Link to={`/forms/${form.id}/edit`}>
                                    {form.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {form.shortCode}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Button
                                    icon={faCopy}
                                    onClick={() => copyStr(form.url, 'Copied URL')}
                                    trigger
                                />
                            </Table.Cell>
                            <Table.Cell collapsing actions>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Edit"
                                        href={`/forms/${form.id}/edit`}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Are you sure you wish to delete this form? This action cannot be undone"
                                        onConfirm={() => deleteForm(form.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Delete"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default FormListView;
