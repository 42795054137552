import React, { FC, HTMLAttributes, isValidElement, useState } from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import Button from '../../elements/Button';
import styles from './styles.module.scss';
import { useEffect } from 'react';

type ModalSize = 'large' | 'medium' | 'small';

export const triggerModalClose = (): void => {
    dispatchEvent(new CustomEvent('trigger-modal-close'));
}

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
    footer?: string | JSX.Element,
    header?: string | JSX.Element,
    onClose?: () => void,
    open?: boolean,
    size?: ModalSize,
    trigger?: JSX.Element,
}

const Modal: FC<ModalProps> = ({
    children,
    className,
    footer,
    header,
    onClose,
    open,
    size,
    trigger,
    ...props
}): JSX.Element => {
    const [ realOpen, setRealOpen ] = useState<boolean>(open || false);
    useEffect(() => setRealOpen(open || false), [open]);

    useEffect(() => {
        const handleTriggerClose = (): void => {
            if (trigger) {
                handleClose();
            }
        }
        window.addEventListener('trigger-modal-close', handleTriggerClose, true);
        return () => window.removeEventListener('trigger-modal-close', handleTriggerClose, true);
    })

    const handleClose = (): void => {
        setRealOpen(false);
        if (onClose) {
            onClose();
        }
    }

    return (<>
        {trigger && React.cloneElement(trigger, { onClick: () => setRealOpen(true) })}
        <div
            className={[
                styles.overlay,
                realOpen ? styles.showModal : '',
            ].join(' ')}
            onClick={handleClose}
        >
            <div
                className={[
                    styles.modal,
                    size ? styles[size] : '',
                    className,
                ].join(' ')}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
                {...props}
            >
                {header && (
                    <div className={styles.header}>
                        <div>
                            {header && isValidElement(header)
                                ? header
                                : <h4 className={styles.title}>{header}</h4>
                            }
                        </div>
                        <Button
                            icon={faTimes}
                            onClick={handleClose}
                            trigger
                        />
                    </div>
                )}
                <div className={styles.body}>
                    {children}
                </div>
                {footer && <div className={styles.footer}>
                    {footer}
                </div>}
            </div>
        </div>
    </>);
}

export default Modal;
