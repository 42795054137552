import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import qs from 'qs';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Checkbox, Form, Grid, Message, Segment, Table, toast } from '../../RbKit';
import logo from '../../modules/TopBar/logo.svg';
import styles from './styles.module.scss';
import api from '../../api';
import { ApiTopic } from '../../api/topic';
import { ApiBig } from '../../api/big';
import { blockComponents } from '../../modules/ContentEditor/Blocks';

const OptView: FC = (): JSX.Element => {
    const { id } = useParams() as any;
    const [ form, setForm ] = useState<any>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ notFound, setNotFound ] = useState<boolean>(false);
    const [ success, setSuccess ] = useState<boolean>(false);
    const [ declined, setDeclined ] = useState<boolean>(false);
    const [ error, setError ] = useState<boolean>(false);
    const [ errors, setErrors ] = useState<any>({});
    const [ bigData, setBigData ] = useState<ApiBig>();
    const [ emailAddress, setEmailAddress ] = useState<string>('');
    const [ bigError, setBigError ] = useState<string>();
    const [ showBig, setShowBig ] = useState<boolean>(false);
    const [ saved, setSaved ] = useState<boolean>(false);
    const location = useLocation();

    const fetch = useCallback((): void => {
        setIsLoading(true);
        api.getFormByShortCode(`${id || '609JGC'}${location.search}`).then(({ data }) => {
            setIsLoading(false);

            const s = qs.parse(location.search.substring(1));
            if (s.d && s.d === '1') {
                setDeclined(true);
                save({
                    ...data,
                    person: {
                        ...(data.person || {}),
                        topics: [],
                        status: 2,
                    },
                });
            } else {
                setForm(data);
                setShowBig(data.person.bigNumber !== null && data.person.bigNumber !== '');
            }
        }).catch(() => {
            setNotFound(true);
            setIsLoading(false);
        });
    }, [id, location]); // eslint-disable-line
    useEffect(() => {
        if (location.search.includes('e')) {
            fetch();
        }
    }, [fetch, location]);

    const sendLink = (): void => {
        if (emailAddress === '') {
            setError(true);
            return;
        }
        setIsLoading(true);
        api.sendConsentLink(id || '609JGC', '', emailAddress).then(() => {
            setSuccess(true);
            setIsLoading(false);
        }).catch(() => {
            setNotFound(true);
            setIsLoading(false);
        });
    }

    const toggleTopic = (topic: ApiTopic): void => {
        if (topic.isMandatory) return;
        const person = form.person || { topics: [] };
        let newTopics: string[] = [];
        
        if ((person.topics || []).includes(topic.id)) {
            newTopics = person.topics.filter((o: string) => o !== topic.id);
        } else {
            newTopics = [ ...(person.topics || []), topic.id ];
        }

        setForm({
            ...form,
            person: {
                ...(form.person || {}),
                topics: newTopics,
                status: 1,
            },
        });
    }

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setForm({
            ...form,
            person: {
                ...form.person,
                [name]: value,
            },
        });
    }

    const save = (d?: any): void => {
        setErrors({});
        setBigError(undefined);
        const f = d || form;

        const person = { ...f.person };
        f.topics.filter((o: ApiTopic) => o.isMandatory).forEach((t: ApiTopic) => {
            person.topics.push(t.id);
        });

        api.saveConsent(`${id || '609JGC'}${location.search}`, f.person).then(() => {
            if (!d) {
                fetch();
                setSaved(true);
                document.querySelector('main')?.scrollTo({ top: 0 })
            }
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error');
        });;
    }

    const checkBig = (): void => {
        setBigError(undefined);
        api.checkBig(form.person.bigNumber.trim()).then(({ data }) => {
            setBigData(data);
        }).catch((err) => setBigError(err.response.data.errors.big || 'Dit BIG nummer is niet bekend in het BIG-register'))
    }

    const decline = (): void => {
        setForm({
            ...form,
            person: {
                ...(form.person || {}),
                topics: [],
                status: 2,
            },
        });
    }

    const renderForm = (): JSX.Element => {
        return (
            <div className={styles.form}>
                <Grid.Row>
                    <Grid.Column md={4} style={{ marginBottom: '1.25rem' }}>
                        <Form.Input
                            error={errors.first_name}
                            label="Voornaam"
                            name="firstName"
                            onChange={handleInput}
                            required
                            value={form.person.firstName || ''}
                        />
                    </Grid.Column>
                    <Grid.Column md={3} className={styles.mid}>
                        <Form.Input
                            label="Tussenv."
                            name="affix"
                            onChange={handleInput}
                            value={form.person.affix || ''}
                        />
                    </Grid.Column>
                    <Grid.Column md={5} style={{ marginBottom: '1.25rem' }}>
                        <Form.Input
                            error={errors.last_name}
                            label="Achternaam"
                            name="lastName"
                            onChange={handleInput}
                            required
                            value={form.person.lastName || ''}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Form.Input
                    error={errors.email_address}
                    label="E-mailadres"
                    name="emailAddress"
                    onChange={handleInput}
                    required
                    value={form.person.emailAddress || ''}
                />

                <Form.Group style={{ marginBottom: '1rem' }}>
                    <span className={styles.moblab} style={{ marginRight: '1rem' }}>
                        Bent u BIG geregistreerd?
                    </span>
                    <Checkbox
                        checked={showBig}
                        label="Ja"
                        radio
                        onChange={() => setShowBig(true)}
                    />
                    <Checkbox
                        checked={!showBig}
                        label="Nee"
                        radio
                        onChange={() => setShowBig(false)}
                    />
                    <p className={styles.info}>
                        Wij vragen uw BIG nummer om te bepalen of u beroepsbeoefenaar bent
                    </p>
                </Form.Group>

                {showBig && (<>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}>
                            <Form.Input
                                error={bigError}
                                label="BIG nummer"
                                name="bigNumber"
                                onChange={handleInput}
                                value={form.person.bigNumber || ''}
                            />
                        </div>
                        {form.person.bigNumber && form.person.bigNumber !== '' && (
                            <Button
                                label="Controleren"
                                onClick={() => checkBig()}
                                style={{ marginLeft: '1rem' }}
                            />
                        )}
                    </div>
                    {bigData && (<>
                        <h3 style={{ margin: '1rem 0 .5rem 0' }}>Gegevens zoals gevonden in het BIG-register</h3>
                        <Table small responsive={false}>
                            <tbody>
                                <Table.Row>
                                    <Table.Cell collapsing>Naam</Table.Cell>
                                    <Table.Cell>{bigData.salutation} {bigData.fullName}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell collapsing>Beroep</Table.Cell>
                                    <Table.Cell>{bigData.profession}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell collapsing>Specialisme</Table.Cell>
                                    <Table.Cell>{bigData.specialism}</Table.Cell>
                                </Table.Row>
                                {bigData.mention !== false && (
                                    <Table.Row>
                                        <Table.Cell collapsing>Vermelding</Table.Cell>
                                        <Table.Cell>{bigData.mentionType}</Table.Cell>
                                    </Table.Row>
                                )}
                                <Table.Row>
                                    <Table.Cell collapsing>Beroepsbeoefenaar</Table.Cell>
                                    <Table.Cell>
                                        {bigData.isPractioner && <FontAwesomeIcon icon={faCheckCircle} className="success" />}
                                    </Table.Cell>
                                </Table.Row>
                            </tbody>
                        </Table>
                    </>)}
                </>)}
            </div>
        )
    }

    const renderTopics = (): JSX.Element => {
        return (<>
            {form.topics.map((topic: any, i: number) => (
                <div
                    key={`topic-${topic.id}`}
                    className={styles.topic}
                    onClick={() => toggleTopic(topic)}
                    style={{
                        cursor: topic.isMandatory ? 'default' : 'pointer',
                        borderBottomColor: i >= form.topics.length - 1 ? '#000000' : '#e5e5e5',
                    }}
                >
                    <div className={styles.topicCheck}>
                        <Checkbox
                            checked={(topic.isMandatory || (form.person && form.person.topics.indexOf(topic.id) !== -1))}
                            disabled={topic.isMandatory}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </div>
                    <div className={styles.topicInfo}>
                        <h3>{topic.name}</h3>
                        <p>
                            {topic.description}
                        </p>
                    </div>
                </div>
            ))}

            <div
                className={styles.topic}
                style={{ borderBottom: 0}}
                onClick={() => decline()}
            >
                <div className={styles.topicCheck}>
                    <Checkbox checked={form.person.status === 2} />
                </div>
                <div className={styles.topicInfo}>
                    <h3>Afmelden voor alle e-mailcommunicatie</h3>
                </div>
            </div>
        </>);
    }

    const renderSubmit = (): JSX.Element => {
        return (
            <div className={styles.btnContainer}>
                <Button
                    label="Voorkeuren opslaan"
                    onClick={() => save()}
                    primary
                />
            </div>
        );
    }

    return (
        <div className={styles.container} style={form ? { width: 600 } : {}}>
            <div className={styles.logo}>
                <img alt="Roche" src={logo} />
            </div>
            <Segment isLoading={isLoading}>
                {notFound ? (
                    <p style={{ margin: 0, textAlign: 'center' }}>
                        Het opgevraagde formulier kon niet worden gevonden.
                    </p>
                ) : success && (
                    <Message
                        title="Link verstuurd"
                        content={`Er is een link verstuurd naar ${emailAddress}`}
                        success
                    />
                )}

                {saved && (
                    <Message
                        title="Voorkeuren opgeslagen"
                        content="Bedankt voor het opgeven van uw voorkeur. Wij hebben uw gegevens in goede orde ontvangen en uw voorkeur wordt verwerkt."
                        success
                    />
                )}

                {declined && (
                    <Message
                        content="U bent afgemeld voor alle e-mailcommunicatie"
                        success
                    />
                )}
                
                {!saved && !notFound && !success && !declined && !form && (
                    <Form onSubmit={(e) => { e.preventDefault(); sendLink(); }}>
                        <h2>Voorkeuren instellen</h2>
                        <p>
                            Na het invullen van uw e-mailadres ontvangt u een link waarmee u uw voorkeuren kunt instellen.
                        </p>
                        <Form.Input
                            error={error && 'Vul een e-mailadres in'}
                            label="E-mailadres"
                            onChange={({ value }: any) => setEmailAddress(value)}
                            value={emailAddress}
                        />
                        <div className={styles.btnContainer}>
                            <Button
                                label="Verstuur mij de link"
                                onClick={() => sendLink()}
                                primary
                            />
                        </div>
                    </Form>
                )}

                {!saved && !notFound && !success && !declined && form && (<>
                    <div>
                        {form.content.map((item: any, index: number) => {
                            if (item.block === 'form') {
                                return renderForm();
                            }
                            if (item.block === 'topics') {
                                return renderTopics();
                            }
                            if (item.block === 'submit') {
                                return renderSubmit();
                            }
                            
                            const Elem = blockComponents[item.block];

                            return (
                                <Elem
                                    key={`content-${index}`}
                                    data={item}
                                    front
                                />
                            );
                        })}
                    </div>
                </>)}
            </Segment>
        </div>
    );
}

export default OptView;
