import React, { FC, HTMLAttributes } from 'react';
import styles from './styles.module.scss';

interface FormGroupProps extends HTMLAttributes<HTMLDivElement> {
    label?: string,
    required?: boolean,
    focus?: boolean,
}

const FormGroup: FC<FormGroupProps> = ({ children, className, label, required, focus, ...props }): JSX.Element => {
    return (
        <div
            className={[
                styles.group,
                focus ? styles.focus : '',
                className,
            ].join(' ')}
            {...props}
        >
            {label && <label className={styles.label}>
                {label}
                {required && <span>*</span>}
            </label>}
            {children}
        </div>
    )
}

export default FormGroup;
