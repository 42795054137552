import { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, request } from './';
import { ApiUser } from './user';

export interface ApiPerson {
    id: string,
    uci?: string,
    firstName: string,
    affix?: string,
    lastName: string,
    name: string,
    emailAddress: string,
    bigNumber: string,
    topics?: any[],
    isSynced: boolean,
    status: number,
    timeline?: {
        user: ApiUser,
        content: string,
        date: string,
    }[],
    tags?: string[],
}

const rest = {
    delete: (personId: string): AxiosPromise => {
        return request.delete(`admin/consents/${personId}`);
    },
    get: (personId: string, qWith?: string,): AxiosPromise<ApiPerson> => {
        return request.get(`admin/consents/${personId}${qWith ? `?with=${qWith}` : ''}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiPerson[]>> => {
        return request.get(`admin/consents?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (person: Partial<ApiPerson>): AxiosPromise<ApiPerson> => {
        return request.post(`admin/consents${person.id ? `/${person.id}` : ''}`, person);
    },
    uploadImport: (file: File): Promise<any> => {
        const data = new FormData();
        data.append('file', file);
        return request.post('admin/consents/upload-import', data);
    },
    saveConsent: (shortCode: string, person: any): AxiosPromise<any> => {
        return request.post(`opt/save/${shortCode}`, person);
    },
    sync: (personId: string): AxiosPromise<any> => {
        return request.post(`admin/consents/${personId}/sync`);
    }
}

export default rest;
