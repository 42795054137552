import qs from 'qs';
import React, { FC, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { NotificationCenter } from './RbKit';
import api from './api';
import { hasRole, useProfile } from './lib/auth';
import TopBar from './modules/TopBar';
import Drawer from './modules/Drawer';
import Loader from './RbKit/elements/Loader';
import FormListView from './views/admin/FormView';
import FormEditView from './views/admin/FormView/Edit';
import UserListView from './views/admin/UserView';
import UserEditView from './views/admin/UserView/Edit';
import PersonListView from './views/admin/PersonView';
import PersonEditView from './views/admin/PersonView/Edit';
import TemplateListView from './views/admin/TemplateView';
import TemplateEditView from './views/admin/TemplateView/Edit';
import TopicListView from './views/admin/TopicView';
import TopicEditView from './views/admin/TopicView/Edit';
import OptView from './views/OptView';
import LoginView from './views/admin/auth/LoginView';
import DocumentListView from './views/admin/DocumentView';
import ImageListView from './views/admin/ImageView';
import ImageViewModal from './views/admin/ImageView/Modal';
import styles from './styles.module.scss';

const App: FC = (): JSX.Element => {
    const [ isLoading, setIsloading ] = useState<boolean>(true);
    const history = useHistory();
    const profile = useProfile();
    const isFront = /(mijnroche|consent-sg\.)/.test(window.location.host) || window.location.host.includes('3001');

    useEffect(() => {
        if (window.location.pathname.includes('opt')) {
            setIsloading(false);
            return;
        }
        api.getProfile().catch(() => {}).finally(() => setIsloading(false));
    }, []);

    useEffect(() => {
        const token = qs.parse(window.location.search.substring(1));
        
		if (token && token._setToken) {
            setIsloading(true);
            localStorage.setItem('accessToken', token._setToken as string);
            api.getProfile().then(() => {
                history.push('/');
            });
		}
    }, [history]);

    let content = (
        <Switch>
            <main className={styles.mainFront}>
                {isFront && <Route component={OptView} exact path="/" />}
                <Route component={OptView} exact path="/opt/:id" />
                {!isFront && <Route component={LoginView} path="*" />}
            </main>
        </Switch>
    );
    
    if (profile) {
        content = (<>
            <Drawer />
            <main className={styles.main}>
                <div>
                    <Switch>
                        {!isFront && <Redirect exact from="/" to="/consents" />}
                        <Route component={DocumentListView} exact path="/media/documents" />
                        <Route component={ImageListView} exact path="/media/images" />
                        <Route component={PersonListView} exact path="/consents" />
                        <Route component={PersonEditView} exact path="/consents/create" />
                        <Route component={PersonEditView} exact path="/consents/:id/edit" />
                        <Route component={FormListView} exact path="/forms" />
                        <Route component={FormEditView} exact path="/forms/create" />
                        <Route component={FormEditView} exact path="/forms/:id/edit" />

                        {hasRole(profile, 'Administrator') && (<>
                            <Route component={TemplateListView} exact path="/admin/templates" />
                            <Route component={TemplateEditView} exact path="/admin/templates/create" />
                            <Route component={TemplateEditView} exact path="/admin/templates/:id/edit" />

                            <Route component={TopicListView} exact path="/admin/topics" />
                            <Route component={TopicEditView} exact path="/admin/topics/create" />
                            <Route component={TopicEditView} exact path="/admin/topics/:id/edit" />

                            <Route component={UserListView} exact path="/admin/users" />
                            <Route component={UserEditView} exact path="/admin/users/create" />
                            <Route component={UserEditView} exact path="/admin/users/:id/edit" />
                        </>)}
                    </Switch>
                </div>
            </main>
            <ImageViewModal open={false} />
        </>);
    }

    return (
        <Router>
            {!isFront && <TopBar />}
            {isLoading ? <Loader /> : content}
            <NotificationCenter />
        </Router>
    )
}

export default App;
