import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Checkbox, Form, PageHeader, Segment, toast } from '../../../RbKit';
import api from '../../../api';
import { ApiTopic } from '../../../api/topic';
import ChangeHandler from '../../../components/ChangeHandler';

interface TopicEditProps extends RouteComponentProps<{ id?: string }> {
}

const TopicEditView: FC<TopicEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ topic, setTopic ] = useState<Partial<ApiTopic>>({});
    const [ changesMade, setChangesMade ] = useState<boolean>(false);

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getTopic(id).then(({ data }) => {
                setTopic(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setTopic({
            ...topic,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putTopic(topic).then(() => {
            setChangesMade(false);
            setIsLoading(false);
            toast('Topic saved succesfully');
            history.push('/admin/topics');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Something went wrong', 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/admin/topics': 'Topics',
                [`/admin/topics/${id ? `${id}/edit` : 'create'}`]: id ? topic.name || 'New' : 'New',
            }}
            title={`${id ? 'Edit' : 'Create'} topic`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <Form.Group>
                    <Checkbox
                        checked={topic.isMandatory}
                        onChange={({ checked }) => handleInput({ name: 'isMandatory', value: checked })}
                        label="Always mandatory"
                    />
                </Form.Group>
                <Form.Input
                    error={errors.name}
                    label="Subject"
                    name="name"
                    onChange={handleInput}
                    required
                    value={topic.name || ''}
                />
                <Form.Textarea
                    label="Description"
                    name="description"
                    onChange={handleInput}
                    rows={6}
                    value={topic.description || ''}
                />
            </Segment>
        </Form>
    </>);
}

export default TopicEditView;
