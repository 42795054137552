import Form from './Form';
import Submit from './Submit';
import Text from './Text';
import Topics from './Topics';

export const BLOCKS: any = {
    Form,
    Submit,
    Text,
    Topics,
};

export const blockComponents: any = {
    form: Form.Block,
    submit: Submit.Block,
    text: Text.Block,
    topics: Topics.Block,
};
