import { AxiosPromise } from 'axios';
import { request } from '.';

export interface ApiBig {
    salutation: string,
    initial: string,
    lastName: string,
    fullName: string,
    gender: string,
    specialism: string,
    profession: string,
    isPractioner: boolean,
    mention: boolean | number,
    mentionType: string,
}

const rest = {
    check: (big: string): AxiosPromise<ApiBig> => {
        return request.get(`big/check?big=${big}`);
    }
}

export default rest;
