import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, PageHeader, Segment, toast } from '../../../RbKit';
import api from '../../../api';
import { ApiForm } from '../../../api/form';
import { ApiTopic } from '../../../api/topic';
import ChangeHandler from '../../../components/ChangeHandler';
import ContentEditor from '../../../modules/ContentEditor';
import SortableList, { reorder } from '../../../components/SortableList';
import styles from './styles.module.scss';

interface FormEditProps extends RouteComponentProps<{ id?: string }> {
}

const FormEditView: FC<FormEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ form, setForm ] = useState<Partial<ApiForm>>({});
    const [ topics, setTopics ] = useState<ApiTopic[]>([]);
    const [ changesMade, setChangesMade ] = useState<boolean>(false);

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getForm(id).then(({ data }) => {
                setForm({
                    ...data,
                    topics: data.topics ? data.topics.map(o => ({
                        text: o.name,
                        value: o.id,
                        id: o.id,
                    })) : [],
                });
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        api.listTopics({ limit: 999 }).then(({ data }) => setTopics(data.data));

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setForm({
            ...form,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putForm({
            ...form,
            topics: form.topics ? form.topics.map((o) => o.id) : [],
        }).then(() => {
            setChangesMade(false);
            setIsLoading(false);
            toast('Form saved succesfully');
            history.push('/forms');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Something went wrong', 'error')
        });
    }

    const onDragEnd = (result: any): void => {
        if (result.destination) {
            setChangesMade(true);
            setForm({
                ...form,
                topics: reorder(form.topics || [], result.source.index, result.destination.index),
            });
        }
    }

    const removeTopic = (id: string): void => {
        setChangesMade(true);
        setForm({
            ...form,
            topics: (form.topics || []).filter((o) => o.id !== id),
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/forms': 'Forms',
                [`/forms/${id ? `${id}/edit` : 'create'}`]: id ? form.name || 'New' : 'New',
            }}
            title={`${id ? 'Edit' : 'Create'} form`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <Form.Input
                    error={errors.name}
                    label="Name"
                    name="name"
                    onChange={handleInput}
                    required
                    value={form.name || ''}
                />
                <Form.Dropdown
                    label="Topics"
                    multiple
                    name="topics"
                    options={topics.map((topic) => ({
                        text: topic.name,
                        value: topic.id,
                    }))}
                    onChange={({ option }) => handleInput({ name: 'topics', value: option.map((o: any) => ({ ...o, id: o.value })) })}
                    hideSelected
                    value={form.topics?.map((o) => o.value)}
                />
                <SortableList
                    items={form.topics || []}
                    onUpdate={onDragEnd}
                    renderListItem={(item) => (
                        <div className={styles.topic}>
                            {item.text}
                            <Button
                                trigger
                                icon={faTrashAlt}
                                onClick={() => removeTopic(item.id)}
                            />
                        </div>
                    )}
                />
            </Segment>
            <ContentEditor
                blocks={form.content || []}
                onUpdate={(content) => {
                    handleInput({ name: 'content', value: content });
                    setChangesMade(true);
                }}
            />
        </Form>
    </>);
}

export default FormEditView;
