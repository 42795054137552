import { faPlus, faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast, useTableSort } from '../../../RbKit';
import api, { ApiMeta } from '../../../api';
import { ApiUser } from '../../../api/user';

const UserListView: FC = (): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ orderBy, order, setSorting ] = useTableSort('firstName');
    const [ users, setUsers ] = useState<ApiUser[]>([]);

    const fetch = useCallback((query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listUsers({ query, page, orderBy, order }).then(({ data }) => {
            setMeta(data.meta);
            setUsers(data.data);
            setIsLoading(false);
        });
    }, [orderBy, order]);
    useEffect(() => fetch(), [orderBy, order, fetch]);

    const deleteUser = (userId: string): void => {
        api.deleteUser(userId).then(() => {
            fetch();
            toast('User deleted successfully');
        });
    }

    return (<>
        <PageHeader title="Users" breadcrumb={{'/admin/users': 'Overview'}}>
            <Button
                href="/admin/users/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>
        <Segment isLoading={isLoading}>
            <Table.Actions
                onSearch={fetch}
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell
                            sorted={[orderBy, 'firstName']}
                            order={order}
                            onSort={setSorting}
                        >
                            Name
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            collapsing
                            sorted={[orderBy, 'emailAddress']}
                            order={order}
                            onSort={setSorting}
                        >
                            E-mailadres
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Rol
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {users.length > 0 ? users.map((user) => (
                        <Table.Row key={`row-${user.id}`}>
                            <Table.Cell primary name="Name">
                                <Link to={`/admin/users/${user.id}/edit`}>
                                    {user.firstName} {user.lastName}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing name="Email address">
                                {user.emailAddress}
                            </Table.Cell>
                            <Table.Cell collapsing name="Role">
                                {user.role}
                            </Table.Cell>
                            <Table.Cell collapsing actions>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Edit"
                                        href={`/admin/users/${user.id}/edit`}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Are you sure you wish to delete this user? This action cannot be undone"
                                        onConfirm={() => deleteUser(user.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Delete"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default UserListView;
