import { capitalize, findIndex } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Button, Checkbox, DropdownOption, Form } from '../../RbKit';
import { ApiImage } from '../../api/image';
import MediaSelector from '../../components/MediaSelector';
import { slugify } from '../../lib/util';
import { BLOCKS } from './Blocks';
import styles from './styles.module.scss';

interface SidebarProps {
    onClose: () => void,
    open: boolean,
}

const Sidebar: FC<SidebarProps> = ({ onClose, open }): JSX.Element | null => {
    const [ block, setBlock ] = useState<any>();
    const [ fields, setFields ] = useState<any[]>();
    const [ ddOptions, setDdOptions ] = useState<{ [key: string]: DropdownOption[] }>({});

    useEffect(() => {
        if (!block) return;

        if (BLOCKS[capitalize(block.block)].loadOptions) {
            block.fields.filter((o: any) => o.type === 'dropdown').forEach((field: any) => {
                BLOCKS[capitalize(block.block)].loadOptions(field.id, (opts: DropdownOption[]) => {
                    setDdOptions((o) => ({
                        ...o,
                        [field.id]: opts,
                    }));
                });
            });
        }
    }, [block]);

    const handleClose = (): void => {
        setBlock(undefined);
        onClose();
    }

    useEffect(() => {
        const handleUpdate = (e: any) => {
            setBlock(e.detail.block);
            setFields([...e.detail.block.fields]);
        }

        window.addEventListener('set-editable-block', handleUpdate);
        return () => window.removeEventListener('set-editable-block', handleUpdate);
    }, []);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        if (!fields) return;
        const index = findIndex(fields, { id: name });
        const newFields = [...fields];
        if (newFields[index].id === '_anchor') {
            newFields[index].value = slugify(value);
        } else {
            newFields[index].value = value;
        }
        setFields(newFields);
    }

    if (!block) {
        return null;
    }

    return (<>
        <div
            className={[
                styles.sidebarOverlay,
                open && styles.show,
            ].join(' ')}
            onClick={() => handleClose()}
        />
        <div
            className={[
                styles.sidebar,
                open && styles.show,
            ].join(' ')}
        >
            <div className={styles.sidebarTitle}>
                <h2>Edit block</h2>
            </div>
            {fields && fields.map((field: any, index: number) => {
                let input = null;

                if (field.type === 'text') {
                    input = (<Form.Input
                        label={field.label}
                        name={field.id}
                        onChange={handleInput}
                        value={field.value}
                    />);
                } else if (field.type === 'wysiwyg') {
                    input = (<Form.Group>
                        <label>{field.label}</label>
                        <Editor
                            apiKey={process.env.REACT_APP_TINYMCE_KEY}
                            value={field.value}
                            init={{
                                height: 200,
                                max_height: 400,
                                menubar: false,
                                plugins: ['code', 'autoresize', 'lists', 'link', 'paste', 'xhtmlxtras'],
                                toolbar: [
                                    'code | pastetext | bold italic underline | subscript superscript | bullist numlist | link unlink | formatselect | styleselect | abbr',
                                ],
                                content_style: block.editorCss || '',
                                autoresize_bottom_margin: 16,
                                force_br_newlines: true,
                                paste_as_text: true,
                                block_formats: 'Heading 1=h1;Heading 2=h2;Paragraph=p',
                                extended_valid_elements: 'span,u,i,em,strong,b',
                                style_formats: [{
                                    title: 'Link: Button',
                                    selector: 'a',
                                    classes: 'button',
                                }, {
                                    title: 'Link: Klinisch onderzoek',
                                    selector: 'a',
                                    classes: 'js-open-indepth',
                                }],
                                setup: (editor) => {
                                    editor.ui.registry.addButton('abbr', {
                                        text: 'Afk.',
                                        onAction: () => {
                                            editor.windowManager.open({
                                                title: 'Voeg een afkorting toe',
                                                body: {
                                                    type: 'panel',
                                                    items: [{
                                                        type: 'input',
                                                        name: 'abbr',
                                                        label: 'Afkorting',
                                                    }, {
                                                        type: 'textarea',
                                                        name: 'title',
                                                        label: 'Omschrijving',
                                                    }]
                                                },
                                                buttons: [{
                                                    type: 'cancel',
                                                    text: 'Annuleren'
                                                }, {
                                                    type: 'submit',
                                                    text: 'Invoegen',
                                                    primary: true
                                                }],
                                                onSubmit: (api) => {
                                                    const data: any = api.getData();
                                                    editor.insertContent(`&nbsp;<abbr title="${data.title}">${data.abbr}</abbr>&nbsp;`);
                                                    api.close();
                                                }
                                            });
                                        }
                                    });
                                }
                            }}
                            onEditorChange={(c: string) => handleInput({ name: field.id, value: c })}
                        />
                    </Form.Group>);
                } else if (field.type === 'image') {
                    input = (<Form.Group>
                        <label>{field.label}</label>
                        <MediaSelector
                            onSelect={(img: ApiImage) => {
                                handleInput({
                                    name: field.id,
                                    value: {
                                        id: img.id,
                                        src: img.src,
                                        width: img.width,
                                        height: img.height,
                                    }
                                });
                            }}
                            thumb={field.value}
                        />
                    </Form.Group>)
                } else if (field.type === 'dropdown') {
                    input = <Form.Dropdown
                        {...field.props}
                        options={ddOptions[field.id] || field.props?.options || []}
                        label={field.label}
                        name={field.id}
                        onChange={handleInput}
                        value={field.value}
                    />
                } else if (field.type === 'checkbox') {
                    input = <Checkbox
                        label={field.label}
                        onChange={({ checked }: any) => handleInput({ name: field.id, value: checked })}
                        checked={field.value}
                    />
                }

                return <div
                    className={styles.sidebarField}
                    key={`sidebar-${index}`}
                >
                    {input}
                </div>
            })}
            <div className={styles.sidebarFooter}>
                <Button link label="Cancel" onClick={() => handleClose()} />
                <Button primary label="Update" onClick={() => handleClose()} />
            </div>
        </div>
    </>);
}

export default Sidebar;
