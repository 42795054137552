import { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, request } from './';

export interface ApiTemplate {
    id: string,
    code: string,
    name: string,
    subject: string,
    content: string,
    customerId?: string,
}

const rest = {
    delete: (templateId: string): AxiosPromise => {
        return request.delete(`admin/templates/${templateId}`);
    },
    get: (templateId: string, qWith?: string): AxiosPromise<ApiTemplate> => {
        return request.get(`admin/templates/${templateId}${qWith ? `?with=${qWith}` : ''}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiTemplate[]>> => {
        return request.get(`admin/templates?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (template: Partial<ApiTemplate>): AxiosPromise<ApiTemplate> => {
        return request.post(`admin/templates${template.id ? `/${template.id}` : ''}`, template);
    },
}

export default rest;
