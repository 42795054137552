import { faPencil, faPlus } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Menu, PageHeader, Segment, Table } from '../../../RbKit';
import api, { ApiMeta } from '../../../api';
import { ApiTemplate } from '../../../api/template';

const TemplateListView: FC<RouteComponentProps> = (): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ templates, setTemplates ] = useState<ApiTemplate[]>([]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listTemplates({ query, page, }).then(({ data }) => {
            setMeta(data.meta);
            setTemplates(data.data);
            setIsLoading(false);
        });
    }

    return (<>
        <PageHeader
            title="Templates"
            breadcrumb={{
                '/admin/templates': 'Templates'
            }}
        >
            <Button
                href="/admin/templates/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>
        
        <Segment isLoading={isLoading}>
            <Table.Actions
                autoLoad
                onSearch={fetch}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Template
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Subject
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {templates.length > 0 ? templates.map((template) => (
                        <Table.Row key={`row-${template.id}`}>
                            <Table.Cell>
                                <Link to={`/admin/templates/${template.id}/edit`}>
                                    {template.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell>
                                {template.subject}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Edit"
                                        href={`/admin/templates/${template.id}/edit`}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default TemplateListView;
