import React, { ChangeEvent, FC, HTMLAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle } from '@fortawesome/pro-solid-svg-icons';
import styles from './styles.module.scss';

interface CheckboxProps {
    checked?: boolean,
    disabled?: boolean,
    label?: string | JSX.Element,
    name?: string,
    onChange?: (data: any, e: ChangeEvent<HTMLInputElement>) => void,
    radio?: boolean,
    required?: boolean,
    toggle?: boolean,
}

const Checkbox: FC<CheckboxProps & HTMLAttributes<HTMLInputElement>> = ({
    checked,
    disabled,
    label,
    name,
    onChange,
    radio,
    toggle,
    ...props
}): JSX.Element => {
    const id = `checkbox-${Math.random() * 9999}`;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        if (onChange) {
            onChange({
                checked: e.currentTarget.checked,
                files: e.currentTarget.files,
                name: e.currentTarget.name,
                value: e.currentTarget.value,
            }, e);
        }
    }

    return (
        <div
            className={[
                toggle ? styles.toggle : styles.base,
                radio && styles.radio,
                disabled && styles.disabled,
            ].join(' ')}
        >
            {!toggle && <FontAwesomeIcon icon={radio ? faCircle : faCheck} />}
            <input
                checked={checked === true}
                id={id}
                name={name}
                onChange={handleChange}
                type={radio ? 'radio' : 'checkbox'}
                {...props}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
}

export default Checkbox;
