import { isString } from 'lodash';
import React, { FC, HTMLAttributes } from 'react';
import { PaddingType } from '../../definitions';
import Loader from '../../elements/Loader';
import styles from './styles.module.scss';

interface SegmentProps extends HTMLAttributes<HTMLDivElement> {
    className?: string,
    elevate?: number,
    header?: string | JSX.Element,
    headerButton?: JSX.Element,
    isLoading?: boolean,
    padding?: PaddingType,
}
const Segment: FC<SegmentProps> = ({
    children,
    className,
    elevate,
    header,
    headerButton,
    isLoading,
    padding,
    ...props
}): JSX.Element => {
    return (
        <div
            className={[
                styles.base,
                styles[`elevate${elevate ?? 1}`],
                padding && styles[`${padding}Padding`],
                className,
            ].join(' ')}
            {...props}
        >
            {header && (
                <div className={styles.header}>
                    {isString(header) ? <h2>{header}</h2> : header}
                    {headerButton}
                </div>
            )}
            {isLoading && <Loader />}
            {children}
        </div>
    );
}

export default Segment;
