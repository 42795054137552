import { faCheckSquare } from '@fortawesome/pro-light-svg-icons';
import React, { FC } from 'react';
import { BlockProps } from '../../../../RbKit';

const Block: FC<BlockProps> = (): JSX.Element => {
    return <div style={{ padding: 100, textAlign: 'center' }}>Topics</div>;
}

const data = {
    Block,
    icon: faCheckSquare,
    getData: () => ({
        block: 'topics',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }],
    })
};

export default data;
