import React, { FC } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

interface SortableListProps {
    customHandle?: boolean
    flex?: boolean,
    items: any[],
    onUpdate: (result: any) => void,
    renderListItem: (item: any, index: number, handle?: any) => JSX.Element,
}

const SortableList: FC<SortableListProps> = ({ customHandle, flex, items, renderListItem, onUpdate }) => {
    return (
        <DragDropContext onDragEnd={onUpdate}>
            <Droppable droppableId="droppable" isCombineEnabled={true}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={flex ? {
                            display: 'grid',
                            gridTemplateColumns: 'auto auto auto',
                        } : {}}
                    >
                        {items.map((item: any, index: number) => (
                            <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...(!customHandle ? provided.dragHandleProps : {})}
                                        style={{
                                            userSelect: 'none',
                                            marginBottom: 5,
                                            opacity: snapshot.isDragging ? .5 : 1,
                                            ...provided.draggableProps.style
                                        }}
                                    >
                                        {renderListItem(item, index, provided.dragHandleProps)}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default SortableList;
