import { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, request } from './';

export interface ApiForm {
    id: string,
    name: string,
    shortCode: string,
    content: any[],
    url: string,
    topics: any[],
}

const rest = {
    delete: (formId: string): AxiosPromise => {
        return request.delete(`forms/${formId}`);
    },
    get: (formId: string): AxiosPromise<ApiForm> => {
        return request.get(`forms/${formId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiForm[]>> => {
        return request.get(`forms?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (form: Partial<ApiForm>): AxiosPromise<ApiForm> => {
        return request.post(`forms${form.id ? `/${form.id}` : ''}`, form);
    },
    getByShortCode: (shortCode: string): AxiosPromise<any> => {
        return request.get(`opt/${shortCode}`);
    },
    sendConsentLink: (shortCode: string, template: string, emailAddress: string): AxiosPromise<any> => {
        return request.post(`opt/${shortCode}/send-link`, { emailAddress, template });
    },
}

export default rest;
