import axios from 'axios';
import auth from './auth';
import document from './document';
import big from './big';
import form from './form';
import image, { ApiImage } from './image';
import person from './person';
import template from './template';
import topic from './topic';
import user from './user';
import tag from './tag';

export const getToken = () => {
    if (localStorage.getItem('accessToken')) {
        return localStorage.getItem('accessToken');
    }
    
    return null;
}

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common = { 'X-Requested-With': 'XMLHttpRequest' };

axios.interceptors.request.use((config) => {
    const accessToken = getToken(); 
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
});

axios.interceptors.response.use((res: any) => res, (err: any) => {
    if (err.message.indexOf(401) !== -1) {
        localStorage.removeItem('accessToken');
    } else if (err.message.indexOf(403) !== -1) {
        window.location.href = '/';
    }
    return Promise.reject(err);
});

export interface ApiMeta {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number,
}

export interface ApiSeo {
    seoTitle: string,
    seoDescription: string,
    seoUrl: string,
    seoImageId?: number,
    seoImage?: ApiImage,
    seoNoIndex: boolean,
}

export interface ApiPaginatedResponse<T> {
    data: T,
    meta: ApiMeta,
    links: {
        first: string,
        last: string,
        next?: string,
        prev?: string,
    },
}

export const request = axios;

const api = {
    checkBig: big.check,

    getProfile: auth.getProfile,
    login: auth.login,
    logout: auth.logout,
    
    deleteDocument: document.delete,
    getDocument: document.get,
    listDocuments: document.list,
    uploadDocument: document.upload,

    deleteForm: form.delete,
    getForm: form.get,
    listForms: form.list,
    putForm: form.put,
    getFormByShortCode: form.getByShortCode,
    sendConsentLink: form.sendConsentLink,

    deleteImage: image.delete,
    getImage: image.get,
    listImages: image.list,
    uploadImage: image.upload,
    listImageFolders: image.listFolders,
    putImageFolder: image.putFolder,

    deletePerson: person.delete,
    getPerson: person.get,
    listPeople: person.list,
    putPerson: person.put,
    importPeople: person.uploadImport,
    saveConsent: person.saveConsent,
    syncConsent: person.sync,

    deleteTemplate: template.delete,
    getTemplate: template.get,
    listTemplates: template.list,
    putTemplate: template.put,

    deleteTopic: topic.delete,
    getTopic: topic.get,
    listTopics: topic.list,
    putTopic: topic.put,

    deleteUser: user.delete,
    getUser: user.get,
    listUsers: user.list,
    putUser: user.put,

    suggestTag: tag.suggest,
};

export default api;
