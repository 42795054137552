import { find } from 'lodash';
import { faCopy, faLink, faPencil, faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faGripHorizontal } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { BlockData, Segment } from '../../RbKit';
import styles from './styles.module.scss';
import { copyStr } from '../../lib/util';

interface BlockContainerProps {
    handle?: any,
    item?: BlockData,
    onDelete?: () => void,
    onDuplicate?: () => void,
    onEdit?: () => void,
    onPlus?: () => void,
}

const BlockContainer: FC<BlockContainerProps> = ({
    children,
    handle,
    item,
    onDelete,
    onDuplicate,
    onEdit,
    onPlus
}): JSX.Element => {
    const [ anchor, setAnchor ] = useState<string>('');

    useEffect(() => {
        if (!item) return;
        const field = find(item.fields, { id: '_anchor' });
        if (field) {
            setAnchor(field.value);
        }
    }, [item]);

    return (
        <div className={styles.blockContainer}>
            <Segment
                className={styles.blockContainerActions}
                elevate={2}
                padding="none"
            >
                {handle && <span {...handle} style={{ cursor: 'move' }}>
                    <FontAwesomeIcon icon={faGripHorizontal} />
                </span>}
                {onEdit && <span onClick={() => onEdit()}>
                    <FontAwesomeIcon icon={faPencil} />
                </span>}
                {onPlus && <span onClick={() => onPlus()}>
                    <FontAwesomeIcon
                        icon={faPlus}
                    />
                </span>}
                {onDuplicate && <span onClick={() => onDuplicate()}>
                    <FontAwesomeIcon
                        icon={faCopy}
                    />
                </span>}
                {onDelete && <span onClick={() => onDelete()}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                </span>}
                {onEdit && <span className={styles.more} onClick={() => anchor === '' ? onEdit() : copyStr(anchor, 'Copied anchor')}>
                    <FontAwesomeIcon icon={faLink} />
                    {anchor !== '' && <span>#{anchor}</span>}
                </span>}
            </Segment>
            <div className={styles.blockContainerContent}>
                {children}
            </div>
        </div>
    );
}

export default BlockContainer;
