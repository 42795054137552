import { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, request } from './';

export interface ApiTopic {
    id: string,
    name: string,
    description: string,
    isMandatory: boolean,
}

const rest = {
    delete: (topicId: string): AxiosPromise => {
        return request.delete(`admin/topics/${topicId}`);
    },
    get: (topicId: string): AxiosPromise<ApiTopic> => {
        return request.get(`admin/topics/${topicId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiTopic[]>> => {
        return request.get(`admin/topics?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (topic: Partial<ApiTopic>): AxiosPromise<ApiTopic> => {
        return request.post(`admin/topics${topic.id ? `/${topic.id}` : ''}`, topic);
    },
}

export default rest;
